.App {
  display: flex;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 0 15px 0;
}

body {
  background-color: #f8f8f8;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
  display: flex;
  align-items: flex-start;
}

.App-header {
  width: 100%;
  position: relative;
  top: 0;
  z-index: 100;
  padding: 30px 0 0 0;
  background-color: #01676c;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  font-family: 'texta-alt-heavy';
  font-size: 40px;
  /*font-size: calc(18px + 2vmin);*/
  color: white;
}

.Title{
  width: 100%;
  position: relative;
  top: 0;
  z-index: 100;
  padding: 30px 0 0 0;
  background-color: #01676c;
  display: flex;
  flex-direction: column;
  font-family: 'texta-alt-heavy';
  font-size: 40px;
  color: white;
  padding-top: 30px;
  align-items: center;
}

.Display-time{
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
  padding: 25px 0 25px 0;
  background-color: #01676c;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'texta-alt-heavy';
  font-size: 35px;
  /*font-size: calc(18px + 2vmin);*/
  color: white;
}

.Components{
  display: flex;
  flex-wrap: wrap;
  background-color: #f8f8f8;
  flex-direction: column;
  font-family: 'texta-alt-heavy';
  align-items: center;
  text-align: center;
  font-size: 25px;
  /*font-size: calc(7px + 2vmin);*/
  color: #01676c;
  padding-top: 10px;
  padding-bottom: 40px;
  position: relative;
  width: 100%;
  min-height:600px;
}

.ReportIntervalContainer{
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.Undertitle{
  display: flex;
  text-align: center;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 20px;
  flex-direction: column;
}

.Input-Field{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  line-height: 0;
}

@keyframes App-logo-spin {  
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
